import React from "react";
import {
  Header,
  Footer,
  SubscribePlan,
  BuyMagazine,
  EmailVerified,
  LinkExpired,
  Dashboard,
  MagazineList,
  ArticleList,
  MagazineDetail,
  BookMarks,
  Plans,
  MyAccount,
  PrivacyPolicy,
  Terms,
  Layout,
  ContactUs,
  FeedBack,
  CategoryDetailsList,
  CategoryList,
  ArticleDetails,
  TopArticles,
  FeaturedArticles,
} from "../../pages";
import { MainRoute } from "../../routes";

import { BreadCrumb, BreadCrumbList } from "../../components";
import { SearchArticles } from "../Search";
// ===========================|| AUTH ROUTING ||=========================== //

const MainRoutes = {
  path: "/",
  element: "",
  // <Layout /> ,
  children: [
    {
      path: "/home",
      element: (
        <>
          <Header />
          <Dashboard />
          <Footer />
        </>
      ),
    },
    {
      path: "/magazines",
      element: (
        <>
          <MainRoute>
            <Header />
            <MagazineList />
            <Footer />
          </MainRoute>
        </>
      ),
    },
    {
      path: "/articles",
      element: (
        <>
          <MainRoute>
            <Header />
            <ArticleList />
            <Footer />
          </MainRoute>
        </>
      ),
    },
    {
      path: "/top-articles",
      element: (
        <>
          <MainRoute>
            <Header />
            <TopArticles />
            <Footer />
          </MainRoute>
        </>
      ),
    },
    {
      path: "/featured-articles",
      element: (
        <>
          <MainRoute>
            <Header />
            <FeaturedArticles />
            <Footer />
          </MainRoute>
        </>
      ),
    },
    {
      path: "/magazine-detail/:magID",
      element: (
        <>
          <MainRoute>
            <Header />
            <MagazineDetail />
            <Footer />
          </MainRoute>
        </>
      ),
    },
    {
      path: "/bookmarks",
      element: (
        <>
          <MainRoute>
            <Header />
            <BookMarks />
            <Footer />
          </MainRoute>
        </>
      ),
    },
    {
      path: "/plans",
      element: (
        <>
          <Header />
          <Plans />
          <Footer />
        </>
      ),
    },
    {
      path: "/account",
      element: (
        <>
          <MainRoute>
            <Header />
            <MyAccount />
            <Footer />
          </MainRoute>
        </>
      ),
    },
    {
      path: "/privacy-policy",
      element: (
        <>
          <Header />
          <PrivacyPolicy />
          <Footer />
        </>
      ),
    },
    {
      path: "/terms",
      element: (
        <>
          <Header />
          <Terms />
          <Footer />
        </>
      ),
    },
    {
      path: "/contact-us",
      element: (
        <>
          <Header />
          <ContactUs />
          <Footer />
        </>
      ),
    },
    {
      path: "/feedback",
      element: (
        <>
          <Header />
          <FeedBack />
          <Footer />
        </>
      ),
    },
    {
      path: "/category-details/:catID",
      element: (
        <>
          <MainRoute>
            <Header />
            <CategoryDetailsList />
            <Footer />
          </MainRoute>
        </>
      ),
    },
    {
      path: "/category-List",
      element: (
        <>
          <MainRoute>
            <Header />
            <CategoryList />
            <Footer />
          </MainRoute>
        </>
      ),
    },
    {
      path: "/article-details/:artID",
      element: (
        <>
          <MainRoute>
            <Header />
            <ArticleDetails />
            <Footer />
          </MainRoute>
        </>
      ),
    },
    {
      path: "/search-articles",
      element: (
        <>
          <MainRoute>
            <Header />
            <SearchArticles />
            <Footer />
          </MainRoute>
        </>
      ),
    },
  ],
};

export default MainRoutes;
