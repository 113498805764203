import { makeStyles } from "@mui/styles";
import { TitleBar, BreadCrumbList } from "../../components";
import { contactus, email, phone, location } from "../../assets";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Container,
  Divider,
  Box,
  Stack,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },

  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    background: "#F6F6F6",
    minHeight: "70vh",
  },
  cardImage: {
    width: "55px",
    // padding: "0px",
    // borderRadius: "3px;"
  },
  cardImageXs: {
    width: "21px",
    marginRight: "12px",
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      padding: "0px",

      "&:last-child": {
        paddingBottom: "0px",
      },
    },
  },
  gridbt: {
    borderBottom: "1px solid #dedede",
  },
  cardIcon: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: 700,
      color: "black",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
  },
  cardDesc: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: 500,
      color: "#000000",
      [theme.breakpoints.down("md")]: {
        background: "#f2f2f2",
        padding: "13px",
        border: "1px solid #d7d7d7",
        borderRadius: "6px",
        marginTop: "0px !important",
      },
    },
  },
}));

//<!-----------------------sample Data------------------>

export function ContactUs() {
  const classes = useStyles();
  return (
    <Grid container className={classes.containerSecound}>
      <Container maxWidth="lg" className={classes.root}>
        <BreadCrumbList />
        <Grid
          container
          className={classes.container}
          spacing={2}
          direction="row"
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <TitleBar Title="Contact Us" />
          </Grid>
          <Grid item md={12}>
            <Card sx={{ display: "flex" }}>
              <Grid container spacing={2} direction="row">
                <Grid item md={6} sx={{ display: { xs: "none", md: "flex" } }}>
                  <CardMedia
                    component="img"
                    sx={{ width: "100%", height: "100%" }}
                    image={contactus}
                    alt="contact us"
                  />
                </Grid>
                <Grid item md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Grid
                        container
                        spacing={{ xs: 2, sm: 2, md: 3 }}
                        direction={{ xs: "column", sm: "row" }}
                      >
                        <Grid item md={12}>
                          <Stack direction={{ xs: "row", sm: "row" }}>
                            <Box sx={{ display: { xs: "flex", md: "none" } }}>
                              <img
                                className={classes.cardImageXs}
                                alt="image"
                                src={phone}
                              />
                            </Box>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              component="div"
                              className={classes.cardIcon}
                            >
                              Phone
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item md={12}>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                          >
                            <Box sx={{ display: { xs: "none", md: "flex" } }}>
                              <img
                                className={classes.cardImage}
                                alt="image"
                                src={phone}
                              />
                            </Box>
                            <Typography className={classes.cardDesc}>
                              +91 82899 12845
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item md={12}>
                          <Stack direction={{ xs: "row", sm: "row" }}>
                            <Box sx={{ display: { xs: "flex", md: "none" } }}>
                              <img
                                className={classes.cardImageXs}
                                alt="image"
                                src={email}
                              />
                            </Box>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              component="div"
                              className={classes.cardIcon}
                            >
                              Email
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item md={12}>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                          >
                            <Box sx={{ display: { xs: "none", md: "flex" } }}>
                              <img
                                className={classes.cardImage}
                                alt="image"
                                src={email}
                              />
                            </Box>
                            <Typography className={classes.cardDesc}>
                              sunnivoiceapp@gmail.com
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item md={12}>
                          <Stack direction={{ xs: "row", sm: "row" }}>
                            <Box sx={{ display: { xs: "flex", md: "none" } }}>
                              <img
                                className={classes.cardImageXs}
                                alt="image"
                                src={location}
                              />
                            </Box>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                              component="div"
                              className={classes.cardIcon}
                            >
                              Address
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item md={12}>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                          >
                            <Box sx={{ display: { xs: "none", md: "flex" } }}>
                              <img
                                className={classes.cardImage}
                                alt="image"
                                src={location}
                              />
                            </Box>
                            <Typography className={classes.cardDesc}>
                              Sunni Voice Fortnightly, Samastha Islamic Centre,
                              Near Planetarium , Kozhikode-6, Kerala, India
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
