import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  Button,
  Stack,
  Box,
} from "@mui/material";
import { plans } from "../../assets";
import { styled } from "@mui/material/styles";

// style constant
const useStyles = makeStyles((theme) => ({
  planIconCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "6px",
    },
  },
  planCardContent: {
    "&.MuiCardContent-root": {
      "&:last-child": {
        padding: "16px !important",
      },
    },
  },
  priceText: {
    "&.MuiTypography-root": {
      fontSize: "32px",
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        fontWeight: 700,
      },
    },
  },
  categoryText: {
    "&.MuiTypography-root": {
      fontSize: 18,
      color: "#E91F29",
      fontWeight: 700,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
        fontWeight: 700,
      },
    },
  },
  planImage: {
    width: 80,
    [theme.breakpoints.down("md")]: {
      width: 45,
    },
  },
  boxCenter: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
  boxCenterFlex: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      display: "flex",
    },
  },
  stackSpace: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "#00C058",
  textTransform: "none",
  fontSize: 20,
  fontWeight: 700,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#00C058",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 15,
  },
}));
// =============================|| SIDE ICON CARD ||============================= //

export const PlansCard = ({
  price,
  category,
  disableButton,
  duration,
  onSubscribeClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Card className={classes.planIconCard}>
      <CardContent className={classes.planCardContent}>
        <Stack
          direction={{ xs: "row", md: "column" }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
          className={classes.stackSpace}
        >
          <Box className={classes.boxCenterFlex}>
            <Box className={classes.boxCenterFlex}>
              <img
                className={classes.planImage}
                alt="image"
                src={plans}
                width="18px"
              />
            </Box>
            <Box
              className={classes.boxCenter}
              sx={{ ml: { xs: "12px", md: "0px" } }}
            >
              <Typography variant="h6" className={classes.priceText}>
                {`₹ ${price} / ${duration}`}
              </Typography>
              <Typography variant="h6" className={classes.categoryText}>
                {category}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.boxCenterFlex}>
            <ColorButton
              variant="contained"
              disableRipple
              disabled={disableButton}
              onClick={onSubscribeClick}
            >
              Subscribe
            </ColorButton>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

PlansCard.propTypes = {
  category: PropTypes.string,
  price: PropTypes.string,
  disableButton: PropTypes.bool,
};
