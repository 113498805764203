import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Link,
  alpha,
  Stack,
  TextField,
  Pagination,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArticleCard,
  TitleBar,
  BreadCrumbList,
  BottomBanner,
} from "../../components";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },

  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {},
    background: "#F6F6F6",
    minHeight: "70vh",
  },
}));

export function SearchArticles() {
  const [articleData, setArticleData] = useState(null);
  const { showToast } = useToast();
  const classes = useStyles();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const getArticles = () => {
    api
      .get(urls.searchArticle, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          keyword: searchInput,
        },
      })
      .then((res) => {
        setArticleData(res.data.data);
        setTotalCount(res.data.data.pagination.total);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const handleArticleClick = (id) => {
    navigate(`/article-details/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const delayedSearch = useCallback(
    debounce((text) => {
      setSearchInput(text.toLowerCase());
    }, 500),
    []
  );

  const handleOnSearch = (event) => {
    delayedSearch(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getArticles();
  }, [searchInput, page, rowsPerPage]);

  return (
    <Grid container className={classes.containerSecound}>
      <Container maxWidth="xl" className={classes.root}>
        <BreadCrumbList />
        <Grid
          container
          className={classes.container}
          spacing={2}
          direction="row"
        >
          <Grid item md={12} xs={12} sm={12}>
            <TitleBar Title="Search Results" />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="left" alignItems="left">
              <TextField
                label="Search Articles"
                variant="outlined"
                onChange={handleOnSearch}
                placeholder="Type to search..."
                sx={{ width: "50%" }}
              />
            </Stack>
          </Grid>

          {articleData?.articles?.map((post, i) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Stack
                  onClick={() => {
                    handleArticleClick(post.id);
                  }}
                >
                  <ArticleCard
                    iconPrimary={post.feature_image}
                    primary={post.title}
                    secondary={post.author.name}
                    authorImage={post.author.image}
                    secondarySub={
                      post.article_categories[0].descriptions[0].category_name
                    }
                    color={post.article_categories[0].category_colour}
                    backgroundColor={alpha(
                      post.article_categories[0].category_colour,
                      0.1
                    )}
                  />
                </Stack>
              </Grid>
            );
          })}
          <Grid
            item
            xs={12}
            style={{ paddingTop: "20px", paddingBottom: "20px" }}
          >
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              rowsPerPage={rowsPerPage}
              component="div"
              align="right"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ mt: "12px", mb: "-4px" }}
            />
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="lg"
        className={classes.root}
        sx={{ background: "white" }}
      >
        <Grid container className={classes.container} direction="row">
          <Grid item md={12} sx={{ width: "100%" }}>
            <BottomBanner />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
