import React, { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { logo, secure, logo1 } from "../../assets";
import {
  NormalTextField,
  PaymentButton,
  DropdownTextField,
  PhoneTextField,
  BuyMagazineCard,
} from "../../components";
import { useToast } from "../../contexts";
import moment from "moment";
import { urls } from "../../services";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiContainer-root": {
      minHeight: "100vh",
    },
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
    },
    background: theme.palette.common.greyBg,
  },
  subContainer: {
    "&.MuiGrid-root": {
      background: "white",
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(-3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  titleBar: {
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImage: {
    width: 220,
    height: 40,
  },
  titleText: {
    display: "flex",
    justifyContent: "center",
    "&.MuiTypography-root": {
      fontSize: 24,
      fontWeight: 600,
    },
  },
  divider: {
    "&.MuiDivider-root": {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(-4),
      marginRight: theme.spacing(-4),
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footerText: {
    display: "flex",
    justifyContent: "center",
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  footerImage: {
    width: 20,
    height: 20,
  },
}));

export function BuyMagazine() {
  const classes = useStyles();
  const { userID, magID } = useParams();
  const { showToast } = useToast();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [magazine, setMagazine] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const api = axios.create({
    baseURL: urls.baseURL.dev,
  });

  const [formData, setFormData] = useState({
    name: "",
    phone_code: null,
    phone_number: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: null,
    zip_code: "",
    errors: {
      name: false,
      phone_code: false,
      phone_number: false,
      address1: false,
      address2: false,
      city: false,
      state: false,
      country: false,
      zip_code: false,
    },
  });
  const [countries, setCountries] = useState([]);

  const getCountries = () => {
    api
      .get(`api/magazine/v1/admin/country_list`)
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        showToast(err.response.data.message, "error");
      });
  };

  const GetMagazine = () => {
    api
      .get(`api/magazine/v1/buy_magazine_details/${userID}/${magID}`)
      .then((res) => {
        setMagazine(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        showToast(err.response.data.message, "error");
      });
  };

  useEffect(() => {
    getCountries();
    GetMagazine();
  }, []);

  const validateForm = () => {
    let invalid = false;
    if (!formData.name) {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.errors.name = true;
        return curr;
      });
      invalid = true;
    }
    if (!formData.phone_code) {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.errors.phone_code = true;
        return curr;
      });
      invalid = true;
    }
    if (!formData.phone_number) {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.errors.phone_number = true;
        return curr;
      });
      invalid = true;
    }
    if (!formData.address1) {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.errors.address1 = true;
        return curr;
      });
      invalid = true;
    }
    if (!formData.address2) {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.errors.address2 = true;
        return curr;
      });
      invalid = true;
    }
    if (!formData.country) {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.errors.country = true;
        return curr;
      });
      invalid = true;
    }
    if (!formData.state) {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.errors.state = true;
        return curr;
      });
      invalid = true;
    }
    if (!formData.city) {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.errors.city = true;
        return curr;
      });
      invalid = true;
    }
    if (!formData.zip_code) {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.errors.zip_code = true;
        return curr;
      });
      invalid = true;
    }
    return invalid;
  };

  const completePayment = (id) => {
    let url = `api/magazine/v1/payment_complete/${userID}/${id}`;
    let params = {
      pg_transaction_id: `txid-${id}`,
      payment_status: true,
      pg_message: "success",
      payment_method: "phonepe",
      payment_gateway_user: formData.name,
      refunded: false,
    };
    api
      .post(url, { ...params })
      .then((res) => {
        showToast(res.data.message, "success");
        setMagazine(null);
        // GetMagazine();
      })
      .catch((err) => {
        console.log(err);
        showToast(err.response.data.message, "error");
      });
  };

  const initiatePayment = (params) => {
    setShowLoader(true);
    let url = `api/magazine/v1/magazine_payment_initiate/${userID}/${magID}`;
    api
      .post(url, { ...params })
      .then((res) => {
        // completePayment(res.data.data.payment_id);
        let url = res.data.data?.payment_link;
        if (url) {
          window.open(url, "_self");
        }
      })
      .catch((err) => {
        console.log(err);
        showToast(err.response.data.message, "error");
        setShowLoader(false);
      });
  };

  const handlePaymentClick = () => {
    if (validateForm()) {
      showToast("All fields are mandatory", "error");
    } else if (!magazine.amount) {
      showToast("Invalid magazine price", "error");
    } else {
      // submit api
      let params = { ...formData };
      delete params.errors;
      params.country = formData.country.id;
      params.phone_code = `+${formData.phone_code.phone_code}`;

      // params["amount"] = magazine.amount;

      initiatePayment(params);
    }
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      return curr;
    });
  };

  useEffect(() => {
    // console.log(formData);
  }, [formData]);

  return (
    <Fragment>
      <Container maxWidth="sm" className={classes.root} disableGutters>
        <Grid
          container
          className={classes.container}
          spacing={3}
          direction="column"
        >
          <Grid item>
            <Box className={classes.titleBar}>
              <img className={classes.logoImage} alt="logo" src={logo1} />
            </Box>
          </Grid>
          <Grid item>
            <Typography className={classes.titleText}>Buy Magazine</Typography>
          </Grid>
          {magazine && (
            <Fragment>
              <Grid item>
                <BuyMagazineCard magazine={magazine} />
              </Grid>
              <Grid item className={classes.subContainer}>
                <Grid container spacing={3} direction="column">
                  <Grid item>
                    <NormalTextField
                      id="name"
                      label="Name"
                      required={true}
                      value={formData.name}
                      error={formData.errors.name}
                      onChange={(e) => updateFormData("name", e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <PhoneTextField
                      id="phone"
                      label="Phone Number"
                      required={true}
                      value={formData.phone_number}
                      error={formData.errors.phone_number}
                      onChange={(e) =>
                        updateFormData("phone_number", e.target.value)
                      }
                      codeOptions={countries}
                      codeValue={formData.phone_code}
                      onCodeChange={(e, v) => updateFormData("phone_code", v)}
                    />
                  </Grid>
                  <Grid item>
                    <NormalTextField
                      id="address-1"
                      label="Address Line 1"
                      multiline={true}
                      required={true}
                      value={formData.address1}
                      error={formData.errors.address1}
                      onChange={(e) =>
                        updateFormData("address1", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <NormalTextField
                      id="address-2"
                      label="Address Line 2"
                      multiline={true}
                      required={true}
                      value={formData.address2}
                      error={formData.errors.address2}
                      onChange={(e) =>
                        updateFormData("address2", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <DropdownTextField
                      id="country"
                      label="Country"
                      options={countries}
                      required={true}
                      value={formData.country}
                      error={formData.errors.country}
                      onChange={(e, v) => {
                        updateFormData("country", v);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <NormalTextField
                      id="state"
                      label="State"
                      required={true}
                      value={formData.state}
                      error={formData.errors.state}
                      onChange={(e) => updateFormData("state", e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <NormalTextField
                      id="city"
                      label="City"
                      required={true}
                      value={formData.city}
                      error={formData.errors.city}
                      onChange={(e) => updateFormData("city", e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <NormalTextField
                      id="pin-code"
                      label="Pin Code"
                      type="number"
                      required={true}
                      value={formData.zip_code}
                      error={formData.errors.zip_code}
                      onChange={(e) =>
                        updateFormData("zip_code", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <PaymentButton onClick={handlePaymentClick}>
                      Proceed to Pay
                    </PaymentButton>
                  </Grid>
                  <Grid item>
                    <Stack
                      className={classes.footerContainer}
                      direction="row"
                      spacing={1}
                    >
                      <img
                        className={classes.tickImage}
                        alt="tick"
                        src={secure}
                      />
                      <Typography className={classes.footerText}>
                        Safe and Secure Payment
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Container>
      <Backdrop in={showLoader}>
        <CircularProgress sx={{ color: "common.greenTxt" }} />
      </Backdrop>
    </Fragment>
  );
}
