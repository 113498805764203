import React, { useState } from "react";
import Button from "@mui/material/Button";

import { Grid, InputLabel } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { NormalTextField } from "../../components";
import { Label } from "@mui/icons-material";
import PropTypes from "prop-types";

//---------------customised button --------->
const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "#EB363F",
  textTransform: "none",
  fontSize: 16,
  fontWeight: 700,
  height: "52px",
  "&:hover": {
    backgroundColor: "#d33039",
  },
}));

const OutlinedButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  color: "#E91F29",
  border: "1px solid #E91F29",
  textTransform: "none",
  fontSize: 16,
  fontWeight: 700,
  height: "52px",
  "&:hover": {
    border: "1px solid #d33039",
    color: "#d33039",
  },
}));

export function EditProfile({ name, email, onCancelClick, onSaveClick }) {
  const [nameField, setNameField] = useState(name);

  return (
    <React.Fragment>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          textAlign: "center",
          color: "black",
          fontWeight: "600",
          fontSize: "20px",
        }}
        id="customized-dialog-title"
      >
        Edit Profile
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="row" p={2}>
          <Grid item xs={12}>
            <InputLabel
              sx={{ fontSize: "16px", fontWeight: "700", color: "black" }}
            >
              Name
            </InputLabel>
            <NormalTextField
              id="name"
              value={nameField}
              onChange={(e) => setNameField(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              sx={{ fontSize: "14px", fontWeight: "900", color: "black" }}
            >
              Email
            </InputLabel>
            <NormalTextField id="email" value={email} disabled="disabled" />
          </Grid>
          <Grid item xs={6}>
            <OutlinedButton
              fullWidth
              variant="outlined"
              sx={{ mt: 1 }}
              onClick={onCancelClick}
            >
              Cancel
            </OutlinedButton>
          </Grid>
          <Grid item xs={6}>
            <ColorButton
              fullWidth
              variant="contained"
              sx={{ mt: 1 }}
              disabled={!nameField.trim()}
              onClick={() => onSaveClick(nameField)}
            >
              Save
            </ColorButton>
          </Grid>
        </Grid>
      </DialogContent>
    </React.Fragment>
  );
}
EditProfile.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
};
