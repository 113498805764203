import React, { useState, useEffect } from "react";
import { Container, Grid, Link, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MagazineCard, TitleBar, BreadCrumbList } from "../../components";
import { useNavigate } from "react-router-dom";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";
import { LatestMagazineCard } from "../../components";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },
  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {},
    background: "#F6F6F6",
    minHeight: "70vh",
  },
}));

//<!-----------------------sample Data------------------>

export function MagazineList() {
  const navigate = useNavigate();
  const [magazineData, setMagazineData] = useState(null);
  const { showToast } = useToast();
  const { logout } = useAuth();

  const handleMagazineClick = (id) => {
    navigate(`/magazine-detail/${id}`);
  };
  const classes = useStyles();

  const getMagazine = () => {
    api
      .get(urls.magazine)
      .then((res) => setMagazineData(res.data.data))

      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
    console.log("setMagazineData", magazineData);
  };

  useEffect(() => {
    getMagazine();
  }, []);

  return (
    <Grid container className={classes.containerSecound}>
      <Container maxWidth="xl" className={classes.root}>
        <BreadCrumbList />

        <Grid
          container
          className={classes.container}
          spacing={2}
          direction="row"
        >
          <Grid item md={12} xs={12} sm={12}>
            <TitleBar Title="Magazines" />
          </Grid>
          {magazineData?.magazines?.map((post, i) => {
            return (
              <Grid item xs={6} sm={4} md={3}>
                <Stack
                  onClick={() => {
                    console.log("Post:", post);
                    handleMagazineClick(post.id);
                  }}
                >
                  {/* <MagazineCard
                    date={post.issue_name}
                    name={post.book_number}
                    image={post.cover_image}
                    year={post.year_number}
                  /> */}
                  <LatestMagazineCard
                    key={i}
                    image={post.cover_image}
                    date={post.issue_name}
                    bookNumber={post.book_number}
                    cardColor={post.colour}
                    onCardClick={() => handleMagazineClick(post.id)}
                    year={post.year_number}
                    isOpened={post.opened}
                  />
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Grid>
  );
}
