import React, { useState, useEffect } from "react";
import { Box, Container, Grid, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArticleCard,
  FeaturedArticleCard,
  TopArticleCard,
  LatestMagazineCard,
  TitleBar,
  CategoryCard,
  BottomBanner,
} from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  cardSpace: {
    "&.MuiGrid-root": {
      padding: "20px 15px 0px 0px",
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },

  containerSecound: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    background: "#F6F6F6",
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

//<!-----------------------sample Data------------------>

export function Dashboard() {
  const [dashboardData, setDashbaordData] = useState(null);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const classes = useStyles();
  const location = useLocation();
  const { logout, isLoggedIn } = useAuth();

  const getDashboard = () => {
    api
      .get(urls.guestDashboard)
      .then((res) => setDashbaordData(res.data.data))
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getDashboard();
  }, []);

  const handleMagazineClick = (magID) => {
    navigate(`/magazine-detail/${magID}`);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          className={classes.container}
          spacing={1}
          direction="row"
        >
          <Grid item xs={12}>
            <TitleBar
              Title="Latest Magazine"
              isViewMore={true}
              link="/magazines"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{ flexWrap: "nowrap", overflowX: "auto" }}
              className="categories-slider"
            >
              {dashboardData?.latest_magazines?.map((post, i) => {
                return (
                  <Grid
                    item
                    sx={{
                      mr: {
                        xs: 1.5,
                        md: 2.5,
                        lg: 3.5,
                      },
                    }}
                  >
                    <LatestMagazineCard
                      key={i}
                      image={post.cover_image}
                      date={post.issue_name}
                      bookNumber={post.book_number}
                      cardColor={post.colour}
                      onCardClick={() => handleMagazineClick(post.id)}
                      year={post.year_number}
                      isOpened={post.opened}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container className={classes.containerSecound} direction="row">
        <Container maxWidth="xl" className={classes.root} spacing={2}>
          <Grid
            container
            className={classes.containerSecound}
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
          >
            <Grid item xs={12}>
              <TitleBar
                Title="Trending Articles"
                isViewMore={true}
                link="/articles"
              />
            </Grid>
            {dashboardData?.trending_articles?.map((post, i) => {
              return (
                <Grid item xs={12} sm={6} lg={4}>
                  <ArticleCard
                    iconPrimary={post.feature_image}
                    primary={post.title}
                    secondary={post.author.name}
                    authorImage={post.author.image}
                    secondarySub={
                      post.article_categories[0].descriptions[0].category_name
                    }
                    color={post.article_categories[0].category_colour}
                    backgroundColor={alpha(
                      post.article_categories[0].category_colour,
                      0.1
                    )}
                    onCardClick={() => {
                      navigate(`/article-details/${post.id}`);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Grid>
      <Container maxWidth="xl" className={classes.root}>
        <Grid container className={classes.container} direction="row">
          <Grid item md={12} xs={12} sm={12}>
            <TitleBar Title="Categories" />
          </Grid>
          <Box
            className="categories-slider"
            sx={{ display: "flex", flexWrap: "no-wrap", overflowX: "auto" }}
          >
            {dashboardData?.article_categories?.map((post, i) => {
              return (
                <Grid key={i} className={classes.cardSpace}>
                  <CategoryCard
                    primaryText={post.descriptions[0].category_name}
                    color={post.category_colour}
                    onItemClick={() => {
                      navigate(`/category-details/${post.id}`);
                    }}
                  />
                </Grid>
              );
            })}
          </Box>
        </Grid>

        <Grid
          container
          className={classes.container}
          spacing={2}
          direction="row"
        >
          <Grid item md={12} xs={12} sm={12}>
            <TitleBar
              Title="Top Articles"
              isViewMore={true}
              link="/top-articles"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              sx={{ flexWrap: "nowrap", overflowX: "auto" }}
              className="categories-slider"
            >
              {dashboardData?.top_articles?.map((post, i) => {
                return (
                  <Grid key={i} item md={3} sx={{ mr: { xs: 1.5, md: 3.8 } }}>
                    <TopArticleCard
                      iconPrimary={post.feature_image}
                      primary={post.title}
                      secondary={post.author.name}
                      authorImage={post.author.image}
                      secondarySub={
                        post.article_categories[0].descriptions[0].category_name
                      }
                      color={post.article_categories[0].category_colour}
                      backgroundColor={alpha(
                        post.article_categories[0].category_colour,
                        0.1
                      )}
                      onCardClick={() => {
                        navigate(`/article-details/${post.id}`);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.container}
          spacing={2}
          direction="row"
        >
          <Grid item md={12} xs={12} sm={12}>
            <TitleBar
              Title="Featured Articles"
              isViewMore={true}
              link="/featured-articles"
            />
          </Grid>
          <Grid item md={4}></Grid>

          <Grid item xs={12}>
            <Grid
              container
              sx={{ flexWrap: "nowrap", overflowX: "auto" }}
              className="categories-slider"
            >
              {dashboardData?.featured_articles?.map((post, i) => {
                return (
                  <Grid item md={4} sx={{ mr: { xs: 1.5, md: 2.5 } }}>
                    <FeaturedArticleCard
                      iconPrimary={post.feature_image}
                      primary={post.title}
                      secondary={post.author.name}
                      authorImage={post.author.image}
                      secondarySub={
                        post.article_categories[0].descriptions[0].category_name
                      }
                      color={post.article_categories[0].category_colour}
                      backgroundColor={alpha(
                        post.article_categories[0].category_colour,
                        0.1
                      )}
                      onCardClick={() => {
                        navigate(`/article-details/${post.id}`);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.container}
          // spacing={2}
          direction="row"
        >
          <Grid item md={12} sx={{ width: "100%" }}>
            <BottomBanner />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
