import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  Avatar,
  Box,
  Stack,
} from "@mui/material";
import { rectangleImage } from "../assets";

import AudioPlayer from "material-ui-audio-player";

// style constant
const useStyles = makeStyles((theme) => ({
  sideIconCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "6px",
      boxShadow: "none !important",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      "&:last-child": {
        padding: "10px !important",
        [theme.breakpoints.down("sm")]: {
          padding: "0px !important",
        },
      },
    },
  },
  primary: {
    "&.MuiTypography-root": {
      color: "#353535;",
      fontFamily: "Baloo Chettan 2;",
      fontSize: "16px;",
      fontStyle: "normal;",
      fontWeight: 600,
      lineHeight: "34px;",
    },
  },
  secondary: {
    "&.MuiTypography-root": {
      color: "#565656",
      fontFamily: "Baloo Chettan 2",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "47px",
    },
  },
  cardImage: {
    width: "-webkit-fill-available",
    padding: " 0px",
    borderRadius: "5px;",
  },
  root: {
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  loopIcon: {
    color: "#3f51b5",
    "&.selected": {
      color: "#0921a9",
    },
    "&:hover": {
      color: "#7986cb",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  playIcon: {
    color: "#8d49f4",
    "&:hover": {
      color: "#7d3be2",
    },
  },
  replayIcon: {
    color: "#e6e600",
  },
  pauseIcon: {
    color: "#0099ff",
  },
  volumeIcon: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  volumeSlider: {
    color: "black",
  },
  progressTime: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  mainSlider: {
    color: "#8d49f4",
    "& .MuiSlider-rail": {
      color: "#7d3be2",
    },
    "& .MuiSlider-track": {
      color: "#7d3be2",
    },
    "& .MuiSlider-thumb": {
      color: "#7d3be2",
    },
  },
  sliderContainerWrapper: {
    flexWrap: "nowrap !important",
    "&.MuiGrid-container": {
      flexWrap: "nowrap !important",
      backgroundColor: "red",
    },
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const Audio = ({ url, author }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  const playerStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "black",
    },
    playIcon: {
      color: "white",
      "&:hover": {
        color: "white",
      },
    },
    pauseIcon: {
      color: "white",
      "&:hover": {
        color: "white",
      },
    },
    progressTime: {
      color: "white",
    },
    mainSlider: {
      color: "lightGrey",
      "& .MuiSlider-rail": {
        color: "lightGrey",
      },
      "& .MuiSlider-track": {
        color: "white",
      },
      "& .MuiSlider-thumb": {
        color: "white",
      },
    },
  }));

  return (
    <Box sx={{ pl: 1, pr: 1, pb: 2, pt: 2 }}>
      <Box
        sx={{
          p: 2,
          backgroundColor: "black",
          borderRadius: "8px",
        }}
      >
        <Stack
          sx={{ mb: 2 }}
          direction={"row"}
          spacing={2}
          alignItems={"center"}
        >
          <Avatar alt="avt" src={author.image} />
          <Stack spacing={2}>
            <Typography sx={{ color: "white" }}>{author.name}</Typography>
          </Stack>
        </Stack>
        <AudioPlayer
          elevation={1}
          variation="default"
          spacing={3}
          order="standart"
          preload="auto"
          useStyles={playerStyles}
          src={url}
          loop={false}
          volume={false}
        />
      </Box>
    </Box>
  );
};

Audio.propTypes = {
  url: PropTypes.string,
};
