import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  Avatar,
  Box,
  Stack,
  Icon,
} from "@mui/material";

import { bookmarkFilled } from "../../assets";

const LINES_TO_SHOW = 2;
const LINES_TO_SHOW_ONE = 1;
// style constant
const useStyles = makeStyles((theme) => ({
  sideIconCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "6px",
      boxShadow: "none",
    },
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      "&:last-child": {
        padding: "16px !important",
      },
    },
  },
  // sideIcon: {
  //   "& > svg": {
  //     width: "32px",
  //     height: "32px",
  //   },
  // },
  premium: {
    "&.MuiTypography-root": {
      marginLeft: "15px",
      fontSize: "18px",
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
  },
  primaryText: {
    "&.MuiTypography-root": {
      marginLeft: "8px",
      fontSize: "16px",
      fontWeight: 500,
      // marginTop: "5px",
      color: "#929292",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
  headerAvatar: {
    "&.MuiAvatar-root": {
      width: "26px",
      height: "26px",
      marginLeft: "15px",
    },
  },
  profileChip: {
    height: "48px",
    alignItems: "center",
    borderRadius: "27px",
    transition: "all .2s ease-in-out",
  },
  bookMarkIcon: {
    "&.MuiSvgIcon-root": {
      width: "18px !important",
      color: "#E91F29",
    },
  },
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
  },
  multiLineEllipsisText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW_ONE,
    "-webkit-box-orient": "vertical",
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const ArticleCard = ({
  iconPrimary,
  primary,
  secondary,
  secondarySub,
  color,
  bgcolor,
  backgroundColor,
  isBookMarked,
  authorImage,
  onCardClick,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? <IconPrimary /> : null;

  return (
    <Box
      sx={{
        borderRadius: "6px",
        boxShadow: "none",
        bgcolor: "white",
        cursor: "pointer",
      }}
      onClick={onCardClick}
    >
      <Grid
        container
        sx={{ padding: 2 }}
        justifyContent="space-between"
        alignItems="top"
      >
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
          }}
        >
          <img
            alt="image"
            src={iconPrimary}
            style={{
              objectFit: "cover",
              borderRadius: "8px",
              width: "100%",
              aspectRatio: "3/4",
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            spacing={{ xs: "4px", md: "8px", lg: "12px" }}
            alignItems={"flex-start"}
          >
            <Grid
              item
              sm={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                style={{
                  padding: "4px",
                  backgroundColor: backgroundColor,
                  borderRadius: "6px",
                  marginLeft: "15px",
                }}
              >
                <Typography
                  variant="body2"
                  align="left"
                  // className={classes.primaryTextBlock}
                  sx={{
                    color: bgcolor ? "#fff" : "grey.700",
                    fontSize: { xs: "12px", lg: "14px" },
                    fontWeight: 600,
                  }}
                >
                  <span style={{ color }}>{secondarySub}</span>
                </Typography>
              </Box>
              {isBookMarked ? (
                <img
                  alt="Bookmarked"
                  src={bookmarkFilled}
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item sm={12}>
              <Typography
                variant="h6"
                className={classes.premium + " " + classes.multiLineEllipsis}
                sx={{ color: bgcolor ? "#fff" : "" }}
              >
                {primary}
              </Typography>
            </Grid>
            <Grid item sm={12} sx={{ display: "flex" }}>
              <Avatar
                src={authorImage}
                className={classes.headerAvatar}
                aria-haspopup="true"
                color="inherit"
              />
              <Typography
                variant="body2"
                align="left"
                className={
                  classes.primaryText + " " + classes.multiLineEllipsisText
                }
              >
                {secondary}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

ArticleCard.propTypes = {
  iconPrimary: PropTypes.object,
  authorImage: PropTypes.object,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  secondarySub: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  bgcolor: PropTypes.string,
  isBookMarked: PropTypes.bool,
  onCardClick: PropTypes.func,
};
