import React, { useState, useEffect } from "react";
import { Container, Grid, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArticleCard, TitleBar, BreadCrumbList } from "../../components";
import { recImage } from "../../assets";
import { api, urls } from "../../services";
import { useToast } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },
  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    background: "#F6F6F6",
    minHeight: "70vh",
  },
}));

export function BookMarks() {
  const navigate = useNavigate();
  const [bookMarkedData, setBookMarkedData] = useState([]);
  const { showToast } = useToast();
  const { logout } = useAuth();
  const classes = useStyles();
  const getBookMarkedArticle = () => {
    api
      .get(urls.bookmarkedArticles)
      .then((res) => setBookMarkedData(res.data.data.articles))
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
    console.log("bookMarkedData", bookMarkedData);
  };
  useEffect(() => {
    getBookMarkedArticle();
  }, []);

  return (
    <Grid container className={classes.containerSecound}>
      <Container maxWidth={"xl"} className={classes.root}>
        <BreadCrumbList />
        <Grid
          container
          className={classes.container}
          spacing={2}
          direction="row"
        >
          <Grid item xs={12}>
            <TitleBar
              Title={
                bookMarkedData.length > 0
                  ? `Bookmarks (${bookMarkedData.length})`
                  : "Bookmarks"
              }
            />
          </Grid>
          {bookMarkedData.map((article) => (
            <Grid item md={4} sm={6} xs={12}>
              <ArticleCard
                isBookMarked={true}
                iconPrimary={article.feature_image}
                primary={article.title}
                secondary={article.author.name}
                authorImage={article.author.image}
                secondarySub={
                  article.article_categories[0].descriptions[0].category_name
                }
                color={article.article_categories[0].category_colour}
                backgroundColor={alpha(
                  article.article_categories[0].category_colour,
                  0.1
                )}
                onCardClick={() => {
                  navigate(`/article-details/${article.id}`);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
}
