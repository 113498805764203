import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  Link,
  Box,
} from "@mui/material";
// style constant
const useStyles = makeStyles((theme) => ({
  sideIconCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "6px",
      boxShadow: "none !important",
    },
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      "&:last-child": {
        padding: "10px !important",
        [theme.breakpoints.down("sm")]: {
          padding: "0px !important",
        },
      },
    },
  },
  primaryTextBlock: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },
  primary: {
    "&.MuiTypography-root": {
      color: "#353535;",
      fontFamily: "Baloo Chettan 2;",
      fontSize: "20px;",
      fontStyle: "normal;",
      fontWeight: 500,
      lineHeight: "34px;",
      margin: "20px 0px !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        margin: " 0px !important",
      },
    },
  },
  secondary: {
    "&.MuiTypography-root": {
      color: "#303030",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "47px",
      margin: "20px 0px !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "27px",
      },
    },
  },
  cardImage: {
    width: "100%",
    maxHeight: "500px",
    // width: "-webkit-fill-available",
    padding: " 0px",
    borderRadius: "5px;",
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const Advertisement = ({ primary, title, image, url }) => {
  const classes = useStyles();

  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Card className={classes.sideIconCard}>
      <CardContent className={classes.sideCardContent}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              style={{
                background: "rgba(8, 148, 148, 0.13)",
                borderRadius: "6px",
                padding: "7px 15px",
                width: "fit-content",
              }}
            >
              <Typography
                variant="body2"
                align="left"
                className={classes.primaryTextBlock}
                sx={{ color: "#007E7E" }}
              >
                <span>Advertisement</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.secondary}>
              <span>{title}</span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.sideIcon}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              window.open(url, "_blank");
            }}
          >
            <img className={classes.cardImage} alt="image" src={image} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.primary}>
              {primary}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Advertisement.propTypes = {
  primary: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
};
