import React, { useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import { Grid, Box, Button, Stack } from "@mui/material";
import { logo, facebook, twitter, youtube, instagram } from "../../assets";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme) => ({
  footerBar: {
    "&.MuiBottomNavigation-root": {
      backgroundColor: "#ffffff",
      height: 140,
      paddingTop: 40,
    },
  },

  logoContainer: {
    display: "flex",
    display: { xs: "none", md: "flex" },
  },
  logoImage: {
    width: 250,
    height: 50,
    cursor: "pointer",
  },
  footerImage: {
    width: 24,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: 20,
    },
  },
  logoImageXs: {
    width: 120,
    height: 30,
    cursor: "pointer",
  },
  rightSideIcon: {
    backgroundColor: "#F2F2F2 !important",
  },
  buttonItem: {
    "&.MuiButton-root": {
      color: "#000",
      textTransform: "none",
      display: "block",
      textAlign: "left",
      fontSize: "18px",
      "&:hover": {
        color: "#000",
        backgroundColor: "white",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
  },
  logoImageXs: {
    width: 120,
    height: 30,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
      padding: "0px 6px",
    },
  },
  footer: {
    "&.MuiStack-root": {
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        justifyContent: "space-between",
        paddingBottom: "40px",
      },
    },
  },
  footerIcons: {
    [theme.breakpoints.down("md")]: {
      padding: "0px 6px",
    },
  },
}));

export function Footer() {
  const pages = ["Products", "Pricing", "Blog"];

  const [value, setValue] = React.useState("recents");
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [options, setOptions] = useState(pages);
  const navigate = useNavigate();

  const handleOptionClick = (i) => {
    setOptions((prev) => {
      const curr = [...prev];
      curr.map((c) => (c.isSelected = false));
      curr[i].isSelected = true;
      return curr;
    });
    navigate(options[i].link);
  };
  const handleOpenMenu = (i) => {
    navigate(`/${i}`);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className={classes.footerBar}
    >
      <Container maxWidth="md">
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 2, md: 3 }}
          className={classes.footer}
        >
          <Box
            className={classes.logoContainer}
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          >
            <img
              className={classes.logoImage}
              alt="logo"
              src={logo}
              onClick={() => handleOptionClick(0)}
            />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
            <img
              className={classes.logoImageXs}
              alt="logo"
              src={logo}
              onClick={() => handleOptionClick(0)}
            />
          </Box>
          <Box>
            {/* <Button
              className={classes.buttonItem}
              onClick={() => handleOpenMenu("magazines")}
            >
              Magazines
            </Button>
            <Button
              className={classes.buttonItem}
              onClick={() => handleOpenMenu("category-List")}
            >
              Categories
            </Button>
            <Button
              className={classes.buttonItem}
              onClick={() => handleOpenMenu("plans")}
            >
              Plans
            </Button>
          </Box>
          <Box>
            <Button
              className={classes.buttonItem}
              onClick={() => handleOpenMenu("account")}
            >
              My Account
            </Button>
            <Button
              className={classes.buttonItem}
              onClick={() => handleOpenMenu("bookmarks")}
            >
              Bookmarks
            </Button> */}
            <Button
              className={classes.buttonItem}
              onClick={() => handleOpenMenu("contact-us")}
            >
              Contact Us
            </Button>
            <Button
              className={classes.buttonItem}
              onClick={() => handleOpenMenu("feedback")}
            >
              Feedback
            </Button>
            <Button
              onClick={() => handleOpenMenu("terms")}
              className={classes.buttonItem}
            >
              Terms & Conditions
            </Button>
          </Box>
          <Box>
            <Button
              className={classes.buttonItem}
              onClick={() => handleOpenMenu("privacy-policy")}
            >
              Privacy Policy
            </Button>

            <Button
              className={classes.buttonItem}
              onClick={() => {
                window.open(
                  "https://merchant.razorpay.com/policy/NMOv8vrs8bw7QX/refund",
                  "_blank"
                );
              }}
            >
              Refund Policy
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://merchant.razorpay.com/policy/NMOv8vrs8bw7QX/shipping",
                  "_blank"
                );
              }}
              className={classes.buttonItem}
            >
              Shipping Policy
            </Button>
          </Box>
          {/* <Box className={classes.footerIcons}>
            <IconButton className={classes.rightSideIcon} sx={{ mr: 2 }}>
              <img
                className={classes.footerImage}
                alt="facebook"
                src={facebook}
              />
            </IconButton>
            <IconButton className={classes.rightSideIcon} sx={{ mr: 2 }}>
              <img
                className={classes.footerImage}
                alt="instagram"
                src={instagram}
              />
            </IconButton>
            <IconButton className={classes.rightSideIcon} sx={{ mr: 2 }}>
              <img
                className={classes.footerImage}
                alt="twitter"
                src={twitter}
              />
            </IconButton>
            <IconButton className={classes.rightSideIcon} sx={{ mr: 2 }}>
              <img
                className={classes.footerImage}
                alt="youtube"
                src={youtube}
              />
            </IconButton>
          </Box> */}
        </Stack>
      </Container>
    </BottomNavigation>
  );
}
