import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { recImage } from "../../assets";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import { Ellipse } from "../../assets";
import PropTypes from "prop-types";
import { Stack, useTheme, Image } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { useAuth } from "../../contexts";

export function LatestMagazineCard({
  image,
  date,
  bookNumber,
  cardColor,
  onCardClick,
  year,
  isOpened,
}) {
  const theme = useTheme();
  const { logout, isLoggedIn } = useAuth();
  return (
    <Card
      sx={{
        paddingTop: { xs: 8, md: 12, lg: 16 },
        boxShadow: "none",
        background: "transparent",
        cursor: "pointer",
      }}
      onClick={onCardClick}
    >
      <CardContent
        sx={{
          paddingX: { xs: 3, md: 4, lg: 5 },
          borderRadius: 2,
          backgroundColor: cardColor === "#000000" ? "lightgray" : cardColor,
          position: "relative",
        }}
      >
        <Stack>
          <ImageWithGreenTick image={image} isSelected={false} />
          <Box sx={{ width: { xs: 150, md: 250, lg: 280 } }}>
            <img
              alt="Magazine Image"
              src={Ellipse}
              style={{
                width: "-webkit-fill-available",
                marginTop: { xs: "0px", md: "10px" },
              }}
            />
          </Box>
        </Stack>
        <Typography
          sx={{
            color: "black",
            textAlign: "center",
            fontFamily: "Urbanist",
            fontSize: { xs: "14px", md: "20px" },
            fontWeight: 700,
            marginTop: { xs: "4px", md: "20px" },
            lineHeight: "20px",
          }}
        >
          {date}
        </Typography>
        <Typography
          sx={{
            color: "#E91F29",
            textAlign: "center",
            fontSize: { xs: "14px", md: "18px" },
            fontFamily: "Baloo Chettan 2",
            fontWeight: 500,
            lineHeight: "20px",
            marginTop: { xs: "6px", md: "15px" },
            marginBottom: { xs: "4px", md: "10px" },
          }}
        >
          {`പുസ്തകം ${bookNumber} - ${year}`}
        </Typography>
      </CardContent>
    </Card>
  );
}
export function ImageWithGreenTick({ image, isSelected }) {
  const theme = useTheme();
  return (
    <Stack
      sx={{ position: "relative", marginTop: { xs: -10, md: -13, lg: -16 } }}
    >
      <Box
        sx={{
          position: "relative",
          width: { xs: 150, md: 250, lg: 280 },
        }}
      >
        <img
          alt="Magazine Image"
          src={image}
          style={{
            width: "100%",
            aspectRatio: 3 / 4,
            borderRadius: "6px",
            objectFit: "cover",
            boxShadow: "none",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            width: { xs: 20, md: 25 },
            height: { xs: 20, md: 25 },
            backgroundColor: "#00C058",
            borderRadius: "2px 8px 2px 8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DoneRoundedIcon
            sx={{
              width: { xs: 15, md: 20 },
              height: { xs: 15, md: 20 },
              color: "white",
            }}
          ></DoneRoundedIcon>
        </Box>
      </Box>
    </Stack>
  );
}

LatestMagazineCard.propTypes = {
  image: PropTypes.string,
  date: PropTypes.string,
  bookNumber: PropTypes.string,
  cardColor: PropTypes.string,
  onCardClick: PropTypes.func,
};
