import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Box, Stack, IconButton, Typography, Tooltip } from "@mui/material";
import {
  exportIcon,
  bookmarkNormal,
  bookmarkFilled,
  clapBlack,
  clapRed,
} from "../assets";
import { Clear } from "@mui/icons-material";
import { debounce } from "lodash";

export const FloatingArticleButtons = ({
  clapsCount,
  userClapsCount,
  isBookMarked,
  onClapClick,
  onBookmarkClick,
  onShareClick,
}) => {
  const [claps, setClaps] = useState(clapsCount);
  const [userClaps, setUserClaps] = useState(userClapsCount);

  useEffect(() => {
    setClaps(clapsCount);
    setUserClaps(userClapsCount);
  }, [clapsCount, userClapsCount]);

  useEffect(() => {
    if (userClaps !== userClapsCount) {
      delayedAPI(userClaps);
    }
  }, [userClaps]);

  const delayedAPI = useCallback(
    debounce((claps) => {
      onClapClick(claps);
    }, 500),
    []
  );

  const handleClap = () => {
    setUserClaps((prev) => {
      if (prev !== 50) {
        return (prev += 1);
      } else {
        return prev;
      }
    });
  };

  return (
    <Box
      sx={{
        pt: 1,
        pb: 1,
        borderRadius: "20px",
        position: "fixed",
        bottom: 20,
        top: "auto",
        left: userClaps ? "calc(50dvw - 105px)" : "calc(50dvw - 90px)",
        backgroundColor: "white",
        boxShadow: 10,
        width: userClaps ? "210px" : "180px",
        zIndex: 1,
      }}
    >
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Stack direction={"row"} alignItems={"center"}>
          <Tooltip title={userClaps > 0 && userClaps} arrow>
            <IconButton sx={{ mr: 1, ml: 1 }} onClick={handleClap}>
              <img
                style={{ height: 28, width: 28 }}
                alt="clap"
                src={userClaps > 0 ? clapRed : clapBlack}
              />
            </IconButton>
          </Tooltip>
          <Typography sx={{ ml: -1, width: 20 }}>{claps}</Typography>
          {userClaps > 0 && (
            <IconButton size="small" onClick={() => setUserClaps(0)}>
              <Clear fontSize="20" />
            </IconButton>
          )}
        </Stack>
        <IconButton sx={{ mr: 1, ml: 1 }} onClick={onBookmarkClick}>
          <img
            style={{ height: 25, width: 25 }}
            alt="bookmark"
            src={isBookMarked ? bookmarkFilled : bookmarkNormal}
          />
        </IconButton>
        <IconButton sx={{ mr: 1, ml: 1 }} onClick={onShareClick}>
          <img style={{ height: 25, width: 25 }} alt="share" src={exportIcon} />
        </IconButton>
      </Stack>
    </Box>
  );
};

FloatingArticleButtons.propTypes = {
  clapsCount: PropTypes.number,
  userClapsCount: PropTypes.number,
  isBookMarked: PropTypes.bool,
  onClapClick: PropTypes.func,
  onBookmarkClick: PropTypes.func,
  onShareClick: PropTypes.func,
};
