import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { api, urls } from "../../services";
import { useAuth } from "../../contexts";

//-------------------animation---------->
import Lottie from "lottie-react";
import { animationOtp } from "../../assets";
//---------------components -------->

import { LoginWrapper, RedLinkButton } from "../../components";
import { useToast } from "../../contexts";
const useStyles = makeStyles((theme) => ({
  titleText: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      fontSize: "28px",
      color: "#1A1A1A",
    },
  },
  titleTextSub: {
    "&.MuiTypography-root": {
      fontWeight: 600,
      fontSize: 16,
      color: "#000",
      marginBottom: "10px",
    },
  },
  linkText: {
    color: "#EB363F",
  },
  otpContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  otpField: {
    border: "1px solid #d9d9d973",
    height: 20,
    backgroundColor: "#d9d9d929",
    padding: 10,
    fontSize: 22,
    fontWeight: 600,
    borderRadius: 8,
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "#EB363F",
  textTransform: "none",
  fontSize: 16,
  fontWeight: 700,
  padding: "10px 16px !important",
  height: "52px",
  "&:hover": {
    backgroundColor: "#d33039",
  },
}));
export function OtpVerification() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const { showToast } = useToast();
  const { login } = useAuth();

  const handleResendClick = () => {
    const params = {
      phone_code: location.state.phone_code,
      phone_number: location.state.phone_number,
    };
    api
      .post(urls.sendOTP, params)
      .then((res) => {
        showToast(res.data.message, "success");
        setOtp("");
      })
      .catch((err) => {
        showToast(err.response?.data?.message, "error");
      });
  };

  const handleConfirmClick = () => {
    if (otp.length === 4) {
      const params = {
        phone_code: location.state.phone_code,
        phone_number: location.state.phone_number,
        otp: otp,
      };
      api
        .post(urls.verifyOTP, params)
        .then((res) => {
          showToast(res.data.message, "success");
          setOpenModal(true);
          setTimeout(function () {
            login();
            res.data.data.is_registered
              ? navigate("/home", {
                  replace: true,
                  state: {
                    first_name: res.data.data.user_data.first_name,
                    email: res.data.data.user_data.email,
                    accept_terms: true,
                  },
                })
              : navigate("/signin-details", {
                  replace: true,
                  state: {
                    phone_code: location.state.phone_code,
                    phone_number: location.state.phone_number,
                    otp: otp,
                    accessTocken: res.data.data.access_token,
                  },
                });
          }, 2000);
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    } else {
      showToast("Enter valid OTP", "error");
    }
  };

  //Modal ----------------------------------------------
  const [openModal, setOpenModal] = React.useState(false);

  const handleClickClose = () => {
    setOpenModal(false);
  };
  //Modal ----------------------------------------------
  return (
    <LoginWrapper>
      <Grid sx={{ textAlign: "center" }}>
        <Typography
          className={classes.titleText}
          gutterBottom
          variant="h5"
          component="div"
        >
          OTP Verification
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              className={classes.titleTextSub}
              variant="subtitle1"
              component="pre"
            >
              {`Enter the OTP you received to\n${location.state.phone_code} ${location.state.phone_number}`}
            </Typography>
          </Grid>
          <Grid item xs className={classes.otpContainer}>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span style={{ margin: 5 }} />}
              renderInput={(props) => <input {...props} />}
              inputStyle={classes.otpField}
              inputType="number"
              shouldAutoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontWeight: 400, fontSize: 16 }}
            >
              Didn't receive code?{" "}
              <RedLinkButton
                className={classes.linkText}
                onClick={handleResendClick}
              >
                Resend Code
              </RedLinkButton>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ColorButton
              variant="contained"
              fullWidth
              disableRipple
              onClick={handleConfirmClick}
              disabled={otp.length !== 4}
            >
              Confirm
            </ColorButton>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={openModal} onClose={handleClickClose}>
        <DialogContent>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12}>
              <Box sx={{ height: "120px" }}>
                <Lottie
                  animationData={animationOtp}
                  loop={false}
                  style={{
                    width: 100,
                    height: 100,
                    margin: "auto",
                  }}
                />
              </Box>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "900", color: "black" }}
              >
                OTP Verified Successfully
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </LoginWrapper>
  );
}
// ==============================|| Login PAGE ||============================== //
