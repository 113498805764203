import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  alpha,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  MagazineCard,
  BreadCrumbList,
  TitleBar,
  TopArticleCard,
  ArticleCard,
} from "../../components";
import { recImage, Ellipse } from "../../assets";
import { styled } from "@mui/material/styles";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";
import { useParams, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },

  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    background: "#F6F6F6",
    minHeight: "70vh",
  },
  cardTop: {
    borderRadius: 6,
  },
  cardLayout: {
    "&.MuiCard-root": {
      borderRadius: "3px",
      boxShadow: "none",
      background: "transparent",
    },
  },
  cardImage: {
    boxShadow: "none",
    width: "100%",
    // height: "235px",
    aspectRatio: "3/4",
    [theme.breakpoints.down("md")]: {
      height: "170px",
      width: "80%",
    },
  },
  cardTitle: {
    "&.MuiTypography-root": {
      fontSize: "24px",
      marginTop: "20px",
      textAlign: "center",
      fontWeight: "700",
      lineHeight: "20px",
      marginBottom: "10px",
      color: " #000",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
  },
  cardSubTitle: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      textAlign: "center",
      fontWeight: "500",
      lineHeight: "20px",
      color: "#E91F29",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
  imageContainer: {
    // margin: '-88px 0 0 auto',
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  EllipseImage: {
    width: "-webkit-fill-available",
    marginTop: "10px",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
}));

//<!-----------------------sample Data------------------>
const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: "#EB363F",
  textTransform: "none",
  fontSize: 20,
  width: "100%",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: "#d33039",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

export function MagazineDetail() {
  const [detailsData, setDetailsData] = useState(null);
  const { magID } = useParams();
  const { showToast } = useToast();
  const classes = useStyles();
  const navigate = useNavigate();
  const { logout, userData } = useAuth();

  const getMagazineDetails = () => {
    api
      .get(urls.magazineDetails + magID)
      .then((res) => setDetailsData(res.data.data))
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  function handleBuyClick() {
    window.open(`/buy-magazine/${userData.id}/${detailsData.id}`, "_blank");
  }

  useEffect(() => {
    getMagazineDetails();
  }, []);

  return (
    <Fragment>
      <Container
        maxWidth="xl"
        className={classes.root}
        sx={{ paddingRight: "0px !important" }}
      >
        <BreadCrumbList />
        <Grid
          container
          className={classes.container}
          spacing={4}
          direction="row"
          justifyContent={"center"}
        >
          <Grid item md={3}>
            <Card
              className={classes.cardLayout}
              // sx={{ maxWidth: { xs: 260, md: 600 } }}
            >
              <CardContent
                className={classes.cardTop}
                sx={{
                  padding: {
                    xs: "20px 30px",
                    md: "20px 50px",
                  },
                  position: "relative",
                  backgroundColor: "#F3F3F3",
                }}
              >
                <Box className={classes.imageContainer}>
                  <Box
                    component={"img"}
                    className={classes.cardImage}
                    alt="Magazine Image"
                    src={detailsData?.cover_image}
                  />
                  <Box
                    component={"img"}
                    className={classes.EllipseImage}
                    alt="Magazine Image"
                    src={Ellipse}
                  />
                </Box>
                <Typography className={classes.cardTitle}>
                  {detailsData?.issue_name}
                </Typography>
                <Typography className={classes.cardSubTitle}>
                  {`പുസ്തകം ${detailsData?.book_number} - ${detailsData?.year_number}`}
                </Typography>
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "center",
                    padding: { xs: 0, md: "20px" },
                    marginTop: { xs: 2, md: 0 },
                  }}
                >
                  {!detailsData?.purchased && !detailsData?.is_free && (
                    <ColorButton
                      variant="contained"
                      disableRipple
                      onClick={() => handleBuyClick()}
                      sx={{ borderRadius: "8px" }}
                    >
                      Buy Magazine
                    </ColorButton>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={8}>
            <Grid container spacing={1} direction="row">
              <Grid item md={12} xs={12} sm={12}>
                <TitleBar Title="Must Read" />
              </Grid>
              {detailsData?.must_read_articles?.map((post, i) => {
                return (
                  <Grid item xs={6} sm={4} md={3} sx={{ mr: 1 }}>
                    <TopArticleCard
                      iconPrimary={post.feature_image}
                      primary={post.title}
                      secondary={post.author.name}
                      authorImage={post.author.image}
                      secondarySub={
                        post.article_categories[0].descriptions[0].category_name
                      }
                      color={post.article_categories[0].category_colour}
                      backgroundColor={alpha(
                        post.article_categories[0].category_colour,
                        0.1
                      )}
                      onCardClick={() => {
                        navigate(`/article-details/${post.id}`);
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container className={classes.containerSecound}>
        <Container maxWidth="xl" className={classes.root}>
          <Grid
            container
            className={classes.container}
            spacing={2}
            direction="row"
            sx={{ paddingRight: "0px !important" }}
          >
            <Grid item xs={12}>
              <TitleBar Title="Articles" />
            </Grid>
            {detailsData?.articles?.map((post, i) => {
              return (
                <Grid item xs={12} sm={6} md={4} sx={{ width: "100%" }}>
                  <ArticleCard
                    iconPrimary={post.feature_image}
                    primary={post.title}
                    secondary={post.author.name}
                    authorImage={post.author.image}
                    secondarySub={
                      post.article_categories[0].descriptions[0].category_name
                    }
                    color={post.article_categories[0].category_colour}
                    backgroundColor={alpha(
                      post.article_categories[0].category_colour,
                      0.1
                    )}
                    onCardClick={() => {
                      navigate(`/article-details/${post.id}`);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Grid>
    </Fragment>
  );
}
